export const tartunlpApi = 'https://api.tartunlp.ai';
export const wordpressUrl = 'https://tartunlp.ai/wordpress/index.php/wp-json/wp/v2';
export const mainPageInfo = '452';
export const dataProtectionPolicy  = '1157';
export const collaboration = '456';
export const api = '461';

export const originalLanguage = 'est';

// Function that takes the original content and strips out all <script> and <style> tags.
export function cleanHtml(content) {
    let div = document.createElement('div');
    div.innerHTML = content;

    let scripts = div.querySelectorAll('style, scripts');
    let i = scripts.length;

    while (i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }

    return div.innerHTML;
}
