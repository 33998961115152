import { useContext, useEffect, useRef, useState } from 'react';
import 'react-dropdown/style.css';
import "./TranslationContainer.scss";
import Box from "./Box";
import CompareButton from "./CompareButton";
import DropDownContainer from "./Dropdown";
import {InputLanguageContext, OutputLanguageContext} from "../../context/Context";
import { getComparisons, getTranslation, scrollToRefTop, sendCorrection, requestData, responseData } from "../../utils/functions";
import { useMediaQuery } from "react-responsive";

export const max_chars = 7000;

const TranslationContainer = ({ height }) => {
    const [compareButtonVisible, toggleCompareButton] = useState(false);
    const [readOnlyOutput, toggleReadOnlyOutput] = useState(true);
    const [needShowHint, toggleNeedShowHint] = useState(false);
    const [inputText, setInputText] = useState('');
    const [charsUsed, setCharsUsed] = useState(0);
    const [outputText, setOutputText] = useState('');
    const [googleText, setGoogleText] = useState('');
    const [tildeText, setTildeText] = useState('');
    const [comparisonsVisible, toggleComparisonsVisible] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const outputBoxRef = useRef(null);
    const outputAreaRef = useRef(null);
    const editButtonRef = useRef(null);

    const [originalOutput, setOriginalOutput] = useState(outputText);

    const { inputLanguage, setInputLanguage } = useContext(InputLanguageContext);
    const { outputLanguage, setOutputLanguage } = useContext(OutputLanguageContext);

    const handleTextChange = e => {
        // Change font size of textareas if the length of text is bigger than 50 characters
        if (isDesktopOrLaptop) {
            const textareas = document.querySelectorAll('.div__textarea');
            textareas.forEach(textarea => {
                if (e.target.value.length >= 200) {
                    textarea.style.fontSize = '16px'
                    textarea.style.lineHeight = '24px'
                }
                else {
                    textarea.style.fontSize = '30px'
                    textarea.style.lineHeight = '35px'

                }
            });
        }
        if (e.target.id === 'input-textarea') {
            setInputText(e.target.value);
            setCharsUsed(e.target.value.length);
        } else {
            setOutputText(e.target.value);
        }
        //setClearButton(e.target.value.length > 0);
    };

    const handleComparisonBoxShow = () => {
        const boxes = document.querySelectorAll('.box');
        if (comparisonsVisible) {
            if (!isDesktopOrLaptop) boxes.forEach(box => box.style.flex = "1 1 50%");
        }
        else {
            if (!isDesktopOrLaptop) {
                boxes.forEach(box => box.style.flex = "1 1 33%");
            }

            // Scroll to comparison box when on mobile
            if (!isDesktopOrLaptop) {
                scrollToRefTop(outputBoxRef);
            }

            /*ReactGA.event({
                category: 'Comparisons',
                action: 'User pressed the "Show comparisons" button',
            });*/
        }

        toggleComparisonsVisible(!comparisonsVisible);
    }

    const switchLanguages = () => {
        const newOutputLanguage = inputLanguage;
        const newOutputText = inputText;
        setInputLanguage(outputLanguage);
        setInputText(outputText);
        setOutputLanguage(newOutputLanguage);
        setOutputText(newOutputText);
    }

    const clearInput = () => {
        setInputText('');
        setOutputText('');
        toggleReadOnlyOutput(true);
        setCharsUsed(0);
    }

    const editOutput = () => {
        toggleReadOnlyOutput(false);
        setOriginalOutput(outputText);
        outputAreaRef.current.focus();
    }
    
    const acceptOutputEditing = () => {
        toggleReadOnlyOutput(true);
        setOriginalOutput(outputText);
        // Send correction to server if the user has changed the output text
        if (outputText !== originalOutput) {
          sendCorrection(outputText, responseData, requestData);
        }
    }

    const cancelOutputEditing = () => {
        toggleReadOnlyOutput(true);
        setOutputText(originalOutput);
    }

    // Get translation when user hasn't typed for 1 second
    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (inputText.length !== 0) {
                getTranslation(inputText, inputLanguage, outputLanguage)
                    .then(data => {
                        setOutputText(data.result);
                        toggleCompareButton(true);
                        toggleReadOnlyOutput(true);
                        if (isDesktopOrLaptop) {
                            const outputBox = document.querySelector('#output-box');
                            outputBox.style.flexBasis = "auto";
                            outputBox.style.flexGrow = 1;
                        }
                    })
                    .catch(err => console.error(err));
            } else {
                setOutputText('');
                toggleCompareButton(false);
                toggleReadOnlyOutput(true);
            }
        }, 1000)

        return () => clearTimeout(typingTimeout)
    }, [inputText, inputLanguage, outputLanguage, toggleCompareButton, isDesktopOrLaptop]);

    // Get comparison texts when user hasn't typed for 3 seconds as to lower the requests made to Google and Tilde
    useEffect(() => {
        const comparisonTimeout = setTimeout(() => {
            if (compareButtonVisible && comparisonsVisible) {
                getComparisons(inputText, inputLanguage, outputLanguage)
                    .then(data => {
                        setGoogleText(data?.google);
                        setTildeText(data?.tilde);
                    })
                    .catch(err => console.error(err));
            }
        }, 3000);

        return () => clearTimeout(comparisonTimeout);
    }, [inputText, comparisonsVisible, compareButtonVisible, inputLanguage, outputLanguage]);

    return (
        <section className={'translation-container'} style={{ height: `${height}px` }}>
            <DropDownContainer switchLanguages={switchLanguages} />
            <div className={'translation-box'}>
                <Box inputBox={true} maxChars={max_chars} text={inputText} handleTextChange={handleTextChange}
                    charsUsed={charsUsed} clearText={clearInput} />
                <Box inputBox={false} maxChars={max_chars} text={outputText} handleTextChange={handleTextChange} 
                    compareButtonVisible={compareButtonVisible} outputBoxRef={outputBoxRef} outputAreaRef={outputAreaRef}
                    setOutputText={setOutputText} readOnly={readOnlyOutput} editOutput={editOutput} acceptEditing={acceptOutputEditing}
                    cancelEditing={cancelOutputEditing} toggleNeedShowHint={toggleNeedShowHint} needShowHint={needShowHint}
                    editButtonRef={editButtonRef} />

                {compareButtonVisible
                    ? <CompareButton googleText={googleText} tildeText={tildeText} comparisonsVisible={comparisonsVisible}
                        handleComparisonBoxShow={handleComparisonBoxShow} />
                    : null
                }
            </div>
        </section>
    );
}

export default TranslationContainer;
