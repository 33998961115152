import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const CompareButton = ({ googleText, tildeText, comparisonsVisible, handleComparisonBoxShow }) => {
    const {t, } = useTranslation();

    if (comparisonsVisible) {
        return (
            <section className={'comparison-container comparison-container--active'}>
                <button className={'section__button'} id={'compare'} onClick={handleComparisonBoxShow}>
                    {t('Compare')}
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
                <div className={'comparison-box'} id={'comparisons'}>
                <span>
                    <p className={'div__paragraph'}>Tilde:</p>
                    <p>{tildeText}</p>
                </span>
                    <span>
                    <p className={'div__paragraph'}>Google:</p>
                    <p>{googleText}</p>
                </span>
                </div>
            </section>
        )
    }
    else {
        return (
            <section className={'comparison-container'}>
                <button className={'section__button'} id={'compare'} onClick={handleComparisonBoxShow}>{t('Compare')}</button>
            </section>
        )
    }
}

export default CompareButton;
